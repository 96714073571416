<template>
  <div>
    <HeaderPanel
      title="Repair Cause"
      :hasDropdown="false"
      :filter="filter"
      placeholder="Search Name"
      routerPath="/setting/repair/repair-cause/detail/0"
      @search="handleSearch"
      :hasFilter="false"
    >
    </HeaderPanel>
    <div class="bg-white border-red px-2 p-lg-3 mt-3">
      <b-table
        striped
        hover
        :busy="isBusy"
        :items="items"
        :fields="fields"
        show-empty
        empty-text="No matching records found"
      >
      <template v-slot:cell(description)="data">
        <span>{{ data.item.description || '-' }}</span>
      </template>
        <template v-slot:cell(action)="data">
          <div class="d-flex align-items-center justify-content-center">
            <font-awesome-icon
              icon="pencil-alt"
              title="detail"
              class="mr-2 pointer text-warning"
              @click="
                $router.push(
                  `/setting/repair/repair-cause/detail/${data.item.id}`
                )
              "
            />

            <b-icon
              @click="handleDelete(data.item.id)"
              icon="trash-fill"
              class="icon-size pointer text-primary"
            /></div
        ></template>

        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="handleChangeTake"
        @pagination="pagination"
        :filter="filter"
        :rows="rows"
      />
    </div>
    <FooterAction routePath="/setting/repair" hideSubmit />
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  components: {},
  validations() {
    return {
      form: {
        prefix: { required },
      },
    };
  },
  data() {
    return {
      isLoading: false,
      items: [],
      isBusy: true,
      filter: {
        page: 1,
        take: 10,
        search: "",
      },
      rows: null,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      fields: [
        { label: "Name", key: "name", tdClass: "w-4" },
        { label: "Description", key: "description", tdClass: "w-4" },
        { label: "Action", key: "action", tdClass: "w-4" },
      ],
    };
  },
  async created() {
    await this.getList();
  },
  methods: {
    async getList() {
      this.isBusy = true;
      const res = await this.axios.post(
        "/repair/RepairCause/List",
        this.filter
      );
      this.items = res.data.detail.data;
      this.rows = res.data.detail.total;
      this.isBusy = false;
    },
    async handleSearch() {
      this.filter.page = 1;
      await this.getList();
    },
    pagination(val) {
      this.filter.page = val;
      this.getData();
    },
    async handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      await this.getList();
    },
    async handleDelete(id) {
      this.confirmAlert().then((val) => {
        if (val.isConfirmed) {
          this.deleteFormId(id);
        }
      });
    },
    async deleteFormId(id) {
      const res = await this.axios.delete(`/repair/DeleteRepairCause/${id}`);
      if (res.data.result) {
        this.successAlert();
        this.getList();
      } else {
        this.errorAlert(res.data.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
